import { Navigate, useRoutes } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
// import DashboardLayout from '../layout/dashboard';
import DashboardAppPage from "../pages/dashboardAppPage";
import Page404 from "../pages/page404";
import DashboardLayout from "../layout/dashboardLayout";
import EventsPage from "../pages/events";
import UserDashboard from "../pages/users";
import AuthLayout from "../layout/authLayout";
import Login from "../pages/auth/signIn";
import OrganizationList from "../pages/organization";
import Settings from "../pages/settings";

export default function Router() {
  const isLoggedIn = secureLocalStorage.getItem("authenticated");
  const routes = useRoutes([
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "/dashboard", element: <DashboardAppPage /> },
        { path: "/events", element: <EventsPage /> },
        { path: "/organizer", element: <OrganizationList /> },
        { path: "/customer", element: <UserDashboard /> },
        { path: "/settings", element: <Settings /> },
      ],
    },
    {
      path: "/",
      element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: "login", element: <Login /> },
        { path: "/", element: <Navigate to="/login" /> },
      ],
    },
    { path: "*", element: isLoggedIn ? <Page404 /> : <Navigate to="/login" /> },
  ]);

  return routes;
}
