import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Router from "./routes";
import store from "./store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingComponent from "./pages/loadingComponent";
import { WEDANCEAPI } from "./services/apiConstant";
import axios from "axios";
import { useEffect, useState } from "react";

function App() {
  const [isVerified, setIsVerified] = useState(true);

  useEffect(() => {
    userDashboardApiCalling();
  }, []);

  const userDashboardApiCalling = () => {
    axios
      .get(`${WEDANCEAPI.userDashboard}`)
      .then((response) => {
        console.log('response == ', response);
        setIsVerified(false);
      })
      .catch((error) => {
        console.log(error);
        setIsVerified(false);
      });
  };
  return (
    <>
      <div className="App">
        {isVerified ? (
          <LoadingComponent />
        ) : (
          <>
            <ToastContainer />
            <BrowserRouter>
              <Provider store={store}>
                <Router />
              </Provider>
            </BrowserRouter>
          </>
        )}
      </div>
    </>
  );
}

export default App;
