import { createSlice } from "@reduxjs/toolkit";
import api from "../../services";
import { API_RESPONSE, WEDANCEAPI } from "../../services/apiConstant";
import { toast } from "react-toastify";

// create slice
// Slice

const slice = createSlice({
  name: "event",
  initialState: {
    eventListData: null,
    eventTypeData: null,
    userListData: [],
    adminDashboardData: null,
    isFetching: false,
    totalData: 0,
    isLoading: false,
  },
  reducers: {
    eventListSuccess(state, action) {
      if (action.payload == null) {
        state.eventListData = [];
      } else {
        state.eventListData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData = action.payload ? action.payload.totalCount : 0;
      }
    },
    eventTypeData: (state, action) => {
      state.eventTypeData = action.payload;
    },
    userListSuccess: (state, action) => {
      state.userListData = action.payload.data;
    },
    allUserListSuccess(state, action) {
      if (action.payload == null) {
        state.userListData = [];
      } else {
        state.userListData = action.payload.data;
        state.isFetching = false;
        state.isLoading = false;
        state.totalData = action.payload ? action.payload.totalCount : 0;
      }
    },
    suspendUserSuccess: (state, action) => {
      const newObjId = action.payload.id;
      const suspendIndex = state.userListData?.findIndex(
        (item) => item.id === newObjId
      );
      let currentUser = action.payload;
      state.userListData[suspendIndex] = currentUser;
    },
    adminDashboardSuccess: (state, action) => {
      state.adminDashboardData = action.payload;
    },
    apiFatchingStartSuccess(state) {
      state.isFetching = true;
    },
    apiFatchingEndSuccess(state) {
      state.isFetching = false;
    },
    clearEventDataSuccess(state) {
      // state.isLoading = true;
      state.isFetching = false;
    },
    verifyOrganiserSuccess: (state, action) => {
      let index = state.userListData.findIndex(
        (item) => item?.id === action.payload.id
      );
      state.userListData[index] = action.payload;
    },
    deleteUserSuccess(state, action) {
      console.log("action == ", action.payload);
      let reqData = action.payload;
      state.userListData = state.userListData.filter(
        (item) => item.id !== reqData
      );
    },
    apiLoadingStartSuccess(state) {
      state.isLoading = true;
    },
    apiLoadingEndSuccess(state) {
      state.isLoading = false;
    },
    clearUserListSuccess(state) {
      state.userListData = [];
      state.totalData = 0;
    },
  },
});

const {
  eventListSuccess,
  eventTypeData,
  userListSuccess,
  allUserListSuccess,
  suspendUserSuccess,
  adminDashboardSuccess,
  apiFatchingStartSuccess,
  apiFatchingEndSuccess,
  clearEventDataSuccess,
  verifyOrganiserSuccess,
  deleteUserSuccess,
  apiLoadingStartSuccess,
  apiLoadingEndSuccess,
  clearUserListSuccess,
} = slice.actions;

// export const eventListForAdmin = (requestParams) => async (dispatch) => {
//   try {
//     await api
//       .post(`${WEDANCEAPI.eventListForAdmin}`, requestParams)
//       .then((response) => {
//         if (response) {
//           if (response.data.status) {
//             // dispatch(eventListSuccess(response.data));
//             dispatch(
//               eventListSuccess({
//                 data: response.data.data,
//                 page: requestParams.page,
//                 totalCount: response.data.count,
//               })
//             );
//           }
//         } else {
//           dispatch(apiFatchingEndSuccess());
//           toast.show("Error", response.data.message);
//         }
//       });
//   } catch (e) {
//     return toast.error(e.message);
//   }
// };

export const eventListForAdmin = (requestParams) => async (dispatch) => {
  api
    .post(`${WEDANCEAPI.eventListForAdmin}`, requestParams)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(
          eventListSuccess({
            data: response.data.data,
            page: requestParams.page,
            totalCount: response.data.count,
          })
        );
      } else {
        dispatch(eventListSuccess(null));
        dispatch(apiFatchingEndSuccess());
        toast.show("Error", null);
      }
    })
    .catch(() => {});
};

export const eventTypes = () => async (dispatch) => {
  api
    .get(`${WEDANCEAPI.eventTypeList}`)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(eventTypeData(result.data));
      }
    })
    .catch(() => {});
};
// export const userList = (page, limit, requestParams) => async (dispatch) => {
//   try {
//     await api
//       .post(`${WEDANCEAPI.userlist}?page=${page}&limit=${limit}`, requestParams)
//       .then((response) => {
//         console.log(response);
//         if (response) {
//           if (response.data.status) {
//             dispatch(userListSuccess(response.data));
//           }
//         } else {
//           toast.show("Error", response.data.message);
//         }
//       });
//   } catch (e) {
//     return toast.error(e.message);
//   }
// };

// export const allUserList = (page, limit, requestParams) => async (dispatch) => {
//   try {
//     await api
//       .post(
//         `${WEDANCEAPI.allUserList}?page=${page}&limit=${limit}`,
//         requestParams
//       )
//       .then((response) => {
//         let result = response.data;
//         // if (result.status) {
//         //   // dispatch(allUserListSuccess(result.data));
//         //   dispatch(allUserListSuccess({ data: result.data, page: page }));
//         // } else {
//         //   toast.error(result.message);
//         // }
//         if (response) {
//           if (response.data.status == 1) {
//             dispatch(
//               allUserListSuccess({
//                 data: result.data,
//                 page: page,
//                 totalCount: result.count,
//               })
//             );
//           } else {
//             dispatch(allUserListSuccess(null));
//           }
//         } else {
//           dispatch(apiFatchingEndSuccess());
//           toast.show("Error", response.data.message);
//         }
//       })
//       .catch(() => { });
//   } catch (e) {
//     return toast.error(e.message);
//   }
// };

export const allUserList = (page, limit, requestParams) => async (dispatch) => {
  api
    .post(
      `${WEDANCEAPI.allUserList}?page=${page}&limit=${limit}`,
      requestParams
    )
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(
          allUserListSuccess({
            data: result.data,
            page: page,
            totalCount: result.count,
          })
        );
      } else {
        dispatch(allUserListSuccess(null));
        dispatch(apiFatchingEndSuccess());
        toast.show("Error", null);
      }
    })
    .catch(() => {});
};

export const suspendUser = (requestParams) => async (dispatch) => {
  try {
    await api
      .post(`${WEDANCEAPI.suspendUser}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (response) {
          if (result.status) {
            toast.success(response.data.message);
            dispatch(suspendUserSuccess(result.data));
          }
        } else {
          toast.error("Error", response.data.message);
        }
      });
  } catch (e) {
    return toast.error(e.message);
  }
};

export const deleteUser = (requestParams) => async (dispatch) => {
  try {
    await api
      .post(`${WEDANCEAPI.deleteUser}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (response) {
          if (result.status) {
            toast.success(response.data.message);
            dispatch(deleteUserSuccess(requestParams.userId));
          }
        } else {
          toast.error("Error", response.data.message);
        }
      });
  } catch (e) {
    return toast.error(e.message);
  }
};

export const adminDashboard = (requestParams) => async (dispatch) => {
  try {
    await api
      .get(`${WEDANCEAPI.adminDashboard}`, requestParams)
      .then((response) => {
        let result = response;
        console.log("result == ", result);
        if (response.status === 200) {
          dispatch(adminDashboardSuccess(result.data));
        } else {
          toast.error(result.message);
        }
      });
  } catch (e) {
    return toast.error(e.message);
  }
};
export const verifyOrganiser = (requestParams) => async (dispatch) => {
  try {
    await api
      .post(`${WEDANCEAPI.verifyOrganiser}`, requestParams)
      .then((response) => {
        let result = response.data;
        if (result.status) {
          dispatch(verifyOrganiserSuccess(result?.data));
        } else {
          toast.error(result.message);
        }
      });
  } catch (e) {
    return toast.error(e.message);
  }
};

export default slice.reducer;
export const apiFatchingStart = () => async (dispatch) => {
  dispatch(apiFatchingStartSuccess());
};

export const apiFatchingEnd = () => async (dispatch) => {
  dispatch(apiFatchingEndSuccess());
};

export const clearEventData = () => async (dispatch) => {
  dispatch(clearEventDataSuccess());
};

export const apiLoadingStart = () => async (dispatch) => {
  dispatch(apiLoadingStartSuccess());
};

export const apiLoadingEnd = () => async (dispatch) => {
  dispatch(apiLoadingEndSuccess());
};

export const clearUserList = () => async (dispatch) => {
  dispatch(clearUserListSuccess());
};
