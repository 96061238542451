import React, { useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import { useDispatch } from "react-redux";
import { deleteUser, suspendUser } from "../../store/slice/event";
import ConfirmDialog from "../../component/dialog/confirmDialog";
const UserListItem = (props) => {
  const { itemData, key, handlemenuOpen, openGroupMenuId } = props;
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState();

  const handleSuspend = () => {
    const data = {
      userId: itemData?.id,
      status: itemData?.isSuspended ? 1 : 0,
    };
    setDialogOpen((dialogOpen) => ({
      open: false,
    }));
    handlemenuOpen();
    dispatch(suspendUser(data));
  };

  const handleDelete = () => {
    console.log("itemData?.id == ", itemData?.id);
    const data = {
      userId: itemData?.id,
    };
    setDialogOpen((dialogOpen) => ({
      open: false,
    }));
    handlemenuOpen();
    dispatch(deleteUser(data));
  };

  return (
    <>
      <div className="row event-row" key={key}>
        <div className="col-10 col-md-11">
          <div className="row">
            <div className="col-12 col-md-2">
              <div className="status-info">
                {itemData.name} {itemData.lastName}
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="status-info">{itemData.userName}</div>
            </div>
            <div className="col-12 col-md-3">
              <div className="one-line">{itemData.email}</div>
            </div>
            <div className="col-12 col-md-1">
              <div className="status-info">
                {itemData.isSuspended ? <>Deactive</> : <>Active</>}
              </div>
            </div>
          </div>
        </div>

        <div className="col-2 col-md-1 align-self-center">
          <div
            className={
              openGroupMenuId
                ? "dropdown justify-content-end active"
                : "dropdown justify-content-end"
            }
            data-dropdown
          >
            <a
              className="dropdown-button d-inline-block"
              data-dropdown-button
              onClick={handlemenuOpen}
            >
              <i
                className="fa-solid fa-circle-ellipsis-vertical fa-2xl"
                style={{ color: "#ff8282" }}
              ></i>
            </a>
            <div className="dropdown-menu information-grid">
              <div className="dropdown-links">
                <a
                  href="#"
                  className="link"
                  onClick={() =>
                    setDialogOpen((dialogOpen) => ({
                      open: true,
                      flag: "suspend",
                      title: itemData.isSuspended
                        ? "Unsuspend Account"
                        : "Suspend Account",
                      message: `Are you sure you want to ${
                        itemData.isSuspended ? "unsuspend " : "suspend "
                      } this account?`,
                    }))
                  }
                >
                  {itemData.isSuspended ? (
                    <> Unsuspend Account</>
                  ) : (
                    <> Suspend Account</>
                  )}
                </a>
                <a
                  href="#"
                  className="link"
                  onClick={() =>
                    setDialogOpen((dialogOpen) => ({
                      open: true,
                      flag: "delete",
                      title: "Delete User",
                      message: `Are you sure you want to delete this user?`,
                    }))
                  }
                >
                  Delete Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        title={dialogOpen?.title}
        message={dialogOpen?.message}
        showDialog={dialogOpen?.open}
        onDialogClose={() => {
          setDialogOpen((dialogOpen) => ({
            open: false,
          }));
        }}
        btnConfirm={() => {
          dialogOpen?.flag == "suspend" ? handleSuspend() : handleDelete();
        }}
      />
    </>
  );
};
export default React.memo(UserListItem);
