/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "../styles.css";
import { Spinner } from "react-bootstrap";
import WeButton from "../../component/button";
import Eventlistheader from "./eventlistheader";
import Eventlistitem from "./eventlistitem";
import WeDropDown from "../../component/dropdown/index";
import {
  apiFatchingEnd,
  apiFatchingStart,
  apiLoadingStart,
  eventList,
  eventListForAdmin,
  eventTypes,
} from "../../store/slice/event";

const EventDashboard = () => {
  const dispatch = useDispatch();
  // let page = 1;
  let limit = 5;

  const [searchInput, setSearchInput] = useState("");
  const [isactiveTwo, setActiveTwo] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [openGroupMenuId, setGroupMenuId] = useState(-1);
  const { eventListData, isFetching, totalData, isLoading } = useSelector(
    (state) => state.event
  );

  useEffect(() => {
    listEventData();
    dispatch(apiLoadingStart());
  }, []);

  const handlePagination = (flag) => {
    dispatch(apiFatchingStart());
    const page = flag === "next" ? pageNo + 1 : pageNo - 1;
    setPageNo(page);
  };

  useEffect(() => {
    listEventData();
  }, [pageNo]);

  useEffect(() => {
    listEventData();
  }, [pageNo]);

  const listEventData = () => {
    let requestParams = {
      page: pageNo,
      perPage: limit,
      search: searchInput,
    };
    dispatch(eventListForAdmin(requestParams));
  };

  const handleSearch = (e) => {
    let search = e.target.value;
    setSearchInput(search);
    let requestParams = {
      page: pageNo,
      perPage: limit,
      search: search,
    };
    dispatch(eventListForAdmin(requestParams));
  };

  return isLoading ? (
    <div
      className="position-absolute top-50 start-50 translate-middle"
      style={{ color: "var(--bs-lt-red)" }}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  ) : (
    <>
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="input-block">
            <label htmlFor="searchEvents" className="form-label">
              Search
            </label>
            <input
              type="text"
              className="form-control"
              value={searchInput}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="ticket-table mt-3">
        <Eventlistheader />
        <div className="eventlistheight">
          {eventListData && eventListData.length !== 0 ? (
            eventListData?.map((item, key) => {
              return (
                <Eventlistitem
                  itemData={item}
                  key={key}
                  isactiveTwo={isactiveTwo}
                  setActiveTwo={setActiveTwo}
                  openGroupMenuId={openGroupMenuId === item?.id}
                  handlemenuOpen={() => {
                    if (openGroupMenuId === item?.id) {
                      setGroupMenuId(-1);
                    } else {
                      setGroupMenuId(item?.id);
                    }
                  }}
                />
              );
            })
          ) : !isLoading ? (
            <p className="text-center pt-5"> No Data Found</p>
          ) : (
            ""
          )}
        </div>

        {eventListData && eventListData.length !== 0 ? (
          <div className="mt-3" style={{ float: "right" }}>
            {isFetching ? (
              <div
                className="text-center"
                style={{ color: "var(--bs-lt-red)" }}
              >
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <a onClick={() => (pageNo > 1 ? handlePagination("pre") : "")}>
                  <i
                    className={
                      pageNo > 1
                        ? "fa-solid fa-circle-chevron-left fa-2xl paginationBtn"
                        : "fa-solid fa-circle-chevron-left fa-2xl paginationDisableBtn"
                    }
                  ></i>
                </a>
                <a
                  className="px-1"
                  onClick={() => {
                    if ((totalData % limit === 0 ?  Math.floor(totalData / limit): Math.floor(totalData / limit) +1) > pageNo) {
                      handlePagination("next");
                    }
                  }}
                >
                  <i
                    className={
                      ((totalData % limit === 0 ?  Math.floor(totalData / limit) : Math.floor(totalData / limit) + 1) > pageNo)               
                      ? "fa-solid fa-circle-chevron-right fa-2xl paginationBtn"
                        : "fa-solid fa-circle-chevron-right fa-2xl paginationDisableBtn"
                    }
                  ></i>
                </a>
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default EventDashboard;
