import React from 'react';

const TotelCounts = ({ ...props }) => {
  const { icon, title, number } = props;

  return (
    <>
      <div className="col-6 col-lg-4 col-xxl-3 mb-3">
        <div className="sales-block">
          <div className="icon">
            <i className={icon}></i>
          </div>
          <div className="content">
            <div className="title">{title}</div>
            <div className="stat">{number}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(TotelCounts);
