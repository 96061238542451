import React, { useEffect } from "react";
import TotelCounts from "./subComponents/totelCounts";
import { useDispatch, useSelector } from "react-redux";
import { adminDashboard } from "../store/slice/event";

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const { adminDashboardData } = useSelector((state) => state.event);
  useEffect(() => {
    dispatch(adminDashboard());
  }, []);

  const formatNumber = (num) => {
    let number = typeof num == 'number' ? num.toString() : num
    number = number?.replace(/,/g, '').replace("$", "")
    if (number > 99999) {
      if (number >= 10000000) {
        return (number / 10000000).toFixed(2) + ' Cr';
      } else if (number >= 100000) {
        return (number / 100000).toFixed(2) + ' Lakh';
      }
      return number.toString();
    } else {
      return num;
    }
  };

  return (
    <>
      <div className="row gx-3 mb-4">
        <TotelCounts
          icon={"fa-solid fa-sitemap"}
          title={"Total Organiser"}
          number={formatNumber(adminDashboardData?.totalOrganiser)}
        />
        <TotelCounts
          icon={"fa-solid fa-users"}
          title={"Total User"}
          number={formatNumber(adminDashboardData?.totalUser)}
        />
        <TotelCounts
          icon={"fa-solid fa-sack-dollar"}
          title={"Total Revenue"}
          number={formatNumber(adminDashboardData?.totalAmount)}
        />
      </div>
      <div className="row gx-3 mb-4">
        <TotelCounts
          icon={"fa-solid fa-calendar-lines-pen"}
          title={"All Events"}
          number={formatNumber(adminDashboardData?.allEvents)}
        />
        <TotelCounts
          icon={"fa-solid fa-rocket-launch"}
          title={"Total Publish Event"}
          number={formatNumber(adminDashboardData?.totalPublishEvent)}
        />
      </div>
    </>
  );
}
