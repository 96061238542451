export const WEDANCEAPI = {
  login: "/admin/adminLogin",
  changePasswordForAdmin: "/admin/changePasswordForAdmin",
  eventListForAdmin: "/admin/eventListForAdmin",
  eventTypeList: "/event/getGenre",
  userlist: "/admin/allUserList",
  allUserList: "/admin/allUserList",
  suspendUser: "/admin/suspendUser",
  adminDashboard: "/admin/adminDashboard",
  verifyOrganiser:'/admin/verifyOrganiser',
  deleteUser:'admin/deleteUser'
};

export const API_RESPONSE = {
  SUCCESS: 1,
  FAILURE: 0,
};
