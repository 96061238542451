import { createSlice } from "@reduxjs/toolkit";
import api from "../../services";
import { WEDANCEAPI } from "../../services/apiConstant";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

// create slice
// Slice

const slice = createSlice({
  name: "auth",
  initialState: {
    loginData: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.loginData = action.payload.data;
    },
    loginFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

const { loginSuccess, loginFailed } = slice.actions;

export const login = (data, navigate) => async (dispatch) => {
  try {
    await api.post(`${WEDANCEAPI.login}`, data).then((response) => {
      if (response) {
        if (response.data.status) {
          // window.localStorage.setItem("auth",response.data);

          secureLocalStorage.setItem(
            "authenticated",
            JSON.stringify(response.data)
          );
          toast.success(response.data.message);
          dispatch(loginSuccess(response.data));
          navigate("/", { replace: true });
        } else {
          toast.error(response.data.message);
          dispatch(loginFailed(response.error));
        }
      }
    });
  } catch (e) {
    return toast.error(e.message);
  }
};

// export const changePasswordForAdmin = (data, navigate) => async (dispatch) => {
//   try {
//     await api
//       .post(`${WEDANCEAPI.changePasswordForAdmin}`, data)
//       .then((response) => {
//         if (response) {
//           if (response.data.status) {
//             secureLocalStorage.setItem(
//               "authenticated",
//               JSON.stringify(response.data)
//             );
//             dispatch(loginSuccess(response.data));
//             navigate("/", { replace: true });
//           }
//         } else {
//           dispatch(loginFailed(response.error));
//         }
//       });
//   } catch (e) {
//     return toast.error(e.message);
//   }
// };

export const changePasswordForAdmin =
  (requestParams, navigate) => async (dispatch) => {
    try {
      await api
        .post(`${WEDANCEAPI.changePasswordForAdmin}`, requestParams)
        .then((response) => {
          let result = response.data;
          if (response.status) {
            toast.success(result.message);
            navigate("/");
          } else {
            toast.error(result.message);
          }
        });
    } catch (e) {
      return toast.error(e.message);
    }
  };

export default slice.reducer;
