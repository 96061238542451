import React, { useRef, useState } from "react";
import ConfirmDialog from "../../component/dialog/confirmDialog";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  suspendUser,
  verifyOrganiser,
} from "../../store/slice/event";
// import { useOutsideAlerter } from "../../utilis/appConstants";

const OrdanizationListItem = (props) => {
  const { itemData, key, handlemenuOpen, openGroupMenuId, handlemenuClose } =
    props;
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState();
  const [verifyDialog, setVerifyDialog] = useState(false);
  const [action, setAction] = useState();
  const handleSuspend = () => {
    console.log("val == ", itemData?.id);
    const data = {
      userId: itemData?.id,
      status: itemData?.isSuspended ? 1 : 0,
    };
    setDialogOpen((dialogOpen) => ({
      open: false,
    }));
    handlemenuOpen();
    dispatch(suspendUser(data));
  };
  const handleVerified = () => {
    let obj = {
      userId: itemData?.id,
      status: action ? 0 : 1,
    };
    dispatch(verifyOrganiser(obj));
    setVerifyDialog(false);
  };

  const handleDelete = () => {
    const data = {
      userId: itemData?.id,
    };
    setDialogOpen((dialogOpen) => ({
      open: false,
    }));
    handlemenuOpen();
    dispatch(deleteUser(data));
  };

  const wrapperRef = useRef(null);

  // const closeMenu = () => {
  //   if (openGroupMenuId) {
  //     handlemenuClose();
  //   } else {
  //     handlemenuOpen();
  //   }
  // };

  // useOutsideAlerter(wrapperRef, closeMenu);

  return (
    <>
      <ConfirmDialog
        title={"Verify User"}
        message={
          action
            ? "Are you sure you want to verify this user?"
            : "Are you sure you want to un-verify this user?"
        }
        showDialog={verifyDialog}
        onDialogClose={() => {
          setVerifyDialog(false);
        }}
        btnConfirm={handleVerified}
      />
      <div className="row event-row" key={key}>
        <div className="col-10 col-md-11">
          <div className="row">
            <div className="col-12 col-md-2">
              <div className="status-info">
                {itemData.name} {itemData.lastName}
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="status-info">{itemData.userName}</div>
            </div>
            <div className="col-12 col-md-3">
              <div
                className="one-line"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={itemData.email}
              >
                {itemData.email}
              </div>
            </div>
            <div className="col-12 col-md-1">
              <div className="status-info">{itemData.totalEvent}</div>
            </div>
            <div className="col-12 col-md-1">
              <div className="status-info">{itemData.publicEvent}</div>
            </div>
            <div className="col-12 col-md-1">
              <div className="status-info">{itemData.draftEvent}</div>
            </div>
            <div className="col-12 col-md-1">
              <div className="status-info">
                {itemData.isSuspended ? <>Deactive</> : <>Active</>}
              </div>
            </div>
            <div className="col-12 col-md-1 mt-1">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  disabled={!itemData?.isOrganizer ? true : false}
                  checked={itemData?.isVerifiedOrganizer}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    setAction(checked);
                    setVerifyDialog(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-2 col-md-1 align-self-center">
          <div
            className={
              openGroupMenuId
                ? "dropdown justify-content-end active"
                : "dropdown justify-content-end"
            }
            data-dropdown
          >
            <a
              className="dropdown-button d-inline-block"
              data-dropdown-button
              onClick={handlemenuOpen}
            >
              <i
                className="fa-solid fa-circle-ellipsis-vertical fa-2xl"
                style={{ color: "#ff8282" }}
              ></i>
            </a>
            <div className="dropdown-menu information-grid" ref={wrapperRef}>
              <div className="dropdown-links">
                <a
                  href="#"
                  className="link"
                  onClick={() =>
                    setDialogOpen((dialogOpen) => ({
                      open: true,
                      flag: "suspend",
                      title: itemData.isSuspended
                        ? "Unsuspend Account"
                        : "Suspend Account",
                      message: `Are you sure you want to ${
                        itemData.isSuspended ? "unsuspend " : "suspend "
                      } this account?`,
                      // id: itemData.id,
                    }))
                  }
                >
                  {itemData.isSuspended ? (
                    <> Unsuspend Account</>
                  ) : (
                    <> Suspend Account</>
                  )}
                </a>
                <a
                  href="#"
                  className="link"
                  onClick={() =>
                    setDialogOpen((dialogOpen) => ({
                      open: true,
                      flag: "delete",
                      title: "Delete User",
                      message: `Are you sure you want to delete this user?`,
                    }))
                  }
                >
                  Delete Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        title={dialogOpen?.title}
        message={dialogOpen?.message}
        showDialog={dialogOpen?.open}
        onDialogClose={() => {
          setDialogOpen((dialogOpen) => ({
            open: false,
          }));
        }}
        btnConfirm={() => {
          dialogOpen?.flag == "suspend" ? handleSuspend() : handleDelete();
        }}
      />
    </>
  );
};
export default React.memo(OrdanizationListItem);
