const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: (
      <i className="fa-solid fa-gauge-high" style={{ color: "#ff8282" }}></i>
    ),
  },
  {
    title: "Events",
    path: "/events",
    icon: (
      <i
        className="fa-regular fa-calendar-days"
        style={{ color: "#ff8282" }}
      ></i>
    ),
  },
  {
    title: "Organizer",
    path: "/organizer",
    icon: <i className="fa-solid fa-sitemap" style={{ color: "#ff8282" }}></i>,
  },
  {
    title: "Customer",
    path: "/customer",
    icon: <i className="fa-solid fa-users" style={{ color: "#ff8282" }}></i>,
  },
  {
    title: "settings",
    path: "/settings",
    icon: <i className="fa-duotone fa-gear" style={{ color: "#ff8282" }}></i>,
  },
];
export default navConfig;
