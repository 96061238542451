import { useState } from "react";
import image from "../../assets/img/wedancemo_logo.svg";
import NavItem from "./navItem";
import Button from "react-bootstrap/Button";
import secureStorage from "react-secure-storage";
import { useNavigate } from "react-router";

function Drawer() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(!show);
  };

  const btnLogout = () => {
    secureStorage.clear();
    navigate("/login");
  };

  return (
    <div className="sidebar-nav">
      <div className="row pt-2 pt-lg-4 pb-2 pb-lg-2">
        <div className="col-6 col-lg-12 d-flex d-lg-block align-items-center">
          <a className="navbar-brand d-flex d-lg-block mb-0 mb-lg-4" href="/">
            <img
              src={image}
              alt="Logo"
              className="d-inline-block align-text-top"
            />
            <span className="d-flex align-items-center d-lg-block mt-1 mt-lg-3 ms-2 ms-lg-0">
              Wedancemo
            </span>
          </a>
        </div>
        <div className="col-6 col-lg-12 d-flex justify-content-end d-lg-none align-items-center">
          <div
            className={show ? "hamburger change" : "hamburger "}
            onClick={handleOpen}
          >
            <div className="bar-line bar1" />
            <div className="bar-line bar2" />
            <div className="bar-line bar3" />
          </div>
        </div>
      </div>
      <ul className={show ? "show" : ""}>
        <NavItem />
      </ul>
      <Button
        variant="dark"
        style={{ position: "fixed", bottom: "0", marginBottom: "30px" }}
        onClick={btnLogout}
      >
        <i
          className="bi bi-box-arrow-left"
          style={{ color: "var(--bs-lt-red)", marginRight: "5px" }}
        ></i>
        Logout
      </Button>
    </div>
  );
}

export default Drawer;
