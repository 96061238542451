import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePasswordForAdmin } from "../../store/slice/auth";
import WeButton from "../../component/button";

export default function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [show, setShow] = useState(false);

  const validateEmpty = (str) => {
    return !str || str.length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmpty(password)) {
      setPasswordError("Please enter password.");
      return;
    }
    if (!validateEmpty(password) && password.length < 8) {
      setPasswordError("Password must be minumum 8 characters.");
      return;
    }

    let requestParam = {
      newPassword: password,
    };
    dispatch(changePasswordForAdmin(requestParam, navigate));
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 10,
      }}
    >
      <div className="contain">
        <h3 className="login-title" style={{ color: "rgb(137 135 135)" }}>
          Change Password
        </h3>
        <div className="login-form">
          <div>
            <label
              className="mt-2"
              htmlFor="password"
              style={{ float: "left", color: "rgb(70 69 69)" }}
            >
              New Password
            </label>
            <div className="input-group mb-3">
              <input
                name="password"
                type={show ? "text" : "password"}
                className="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter new password"
                onChange={(e) => {
                  let val = e.target.value.trim();
                  if (!validateEmpty(val) && val.length < 8) {
                    setPasswordError("Password must be minumum 8 characters.");
                  } else {
                    setPassword(val);
                    setPasswordError("");
                  }
                }}
              />

              <span
                onClick={() => {
                  setShow(!show);
                }}
                className="input-group-text"
                id="basic-addon1"
              >
                {show ? (
                  ""
                ) : (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye-slash"></i>
                  </span>
                )}
                {show ? (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye"></i>
                  </span>
                ) : (
                  ""
                )}
              </span>
            </div>

            {passwordError && (
              <div style={{ color: "red" }}>{passwordError}</div>
            )}
          </div>
          <WeButton text="Submit" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
}
