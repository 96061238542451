import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import WeButton from "../../component/button";
import { login } from "../../store/slice/auth";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  let [emailError, setEmailError] = useState("");

  let isLogin = secureLocalStorage.getItem(
    process.env.REACT_APP_AUTH_STORAGE_KEY
  );
  const validateEmpty = (str) => {
    return !str || str.length === 0;
  };
  const validEmail = (val) => {
    const pattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    return pattern.test(val);
  };
  useEffect(() => {
    if (isLogin) {
      navigate("/", true);
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmpty(email)) {
      setEmailError("Please enter email.");
      return;
    }
    if (!validateEmpty(email) && !validEmail(email)) {
      setEmailError("Please enter valid email.");
      return;
    }
    if (validateEmpty(password)) {
      setEmailError("Please enter password.");
      return;
    }
    if (!validateEmpty(password) && password.length < 8) {
      setPasswordError("Password must be minumum 8 characters.");
      return;
    }

    let requestParam = {
      email: email,
      password: password,
    };
    dispatch(login(requestParam, navigate));
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 10,
      }}
    >
      <div className="contain">
        <h2 className="login-title" style={{ color: "rgb(137 135 135)" }}>
          Log in
        </h2>
        <div className="login-form">
          <div>
            <label
              className="mt-2"
              htmlFor="email  "
              style={{ float: "left", color: "rgb(70 69 69)" }}
            >
              Email
            </label>
            <input
              name="email"
              placeholder="me@example.com"
              onChange={(e) => {
                let val = e.target.value.trim();
                if (!validateEmpty(val) && !validEmail(val)) {
                  setEmailError("Please enter valid email");
                } else {
                  setEmail(val);
                  setEmailError("");
                }
              }}
            />
          </div>
          {emailError && <div style={{ color: "red" }}>{emailError}</div>}
          <div>
            <label
              className="mt-2"
              htmlFor="password"
              style={{ float: "left", color: "rgb(70 69 69)" }}
            >
              Password
            </label>
            <div className="input-group mb-3">
              <input
                name="password"
                type={show ? "text" : "password"}
                className="form-control"
                aria-describedby="basic-addon1"
                placeholder="password"
                onChange={(e) => {
                  let val = e.target.value.trim();
                  if (!validateEmpty(val) && val.length < 8) {
                    setPasswordError("Password must be minumum 8 characters.");
                  } else {
                    setPassword(val);
                    setPasswordError("");
                  }
                }}
              />

              <span
                onClick={() => {
                  setShow(!show);
                }}
                className="input-group-text"
                id="basic-addon1"
              >
                {show ? (
                  ""
                ) : (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye-slash"></i>
                  </span>
                )}
                {show ? (
                  <span id="basic-addon2">
                    <i className="fa-light fa-eye"></i>
                  </span>
                ) : (
                  ""
                )}
              </span>
            </div>

            {passwordError && (
              <div style={{ color: "red" }}>{passwordError}</div>
            )}
          </div>
          {/* <a href="forgotPassword" className="linkText rightText">
            Forget Password?
          </a> */}
          <WeButton text="Login" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Login;
