import React from 'react';
const EventListHeader = () => {
  return (
    <div className="row heading">
      <div className="col-10 col-md-11">
        <div className="row">
          <div className="col-12 col-md-7">Event</div>
          <div
            className="col-12 col-md-5 d-none
                      d-md-block">
            <div className="row">
              <div className="col-12 col-md-4">Sold</div>
              <div className="col-12 col-md-4">Revenue</div>
              <div className="col-12 col-md-4">Status</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2 col-md-1"></div>
    </div>
  );
};
export default React.memo(EventListHeader);
