import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Spinner } from "react-bootstrap";
import {
  allUserList,
  apiFatchingEnd,
  apiFatchingStart,
  apiLoadingStart,
  clearUserList,
  eventList,
  eventTypes,
  userList,
} from "../../store/slice/event";
import Userlistheader from "./userlistheader";
import Userlistitem from "./userlistitem";
import { toast } from "react-toastify";

const UserDashboard = () => {
  const dispatch = useDispatch();
  let page = 1;
  let limit = 8;

  const [searchInput, setSearchInput] = useState("");
  const [pageNo, setPageNo] = useState(page);
  const [openGroupMenuId, setGroupMenuId] = useState(-1);
  const [isactiveTwo, setActiveTwo] = useState(false);
  const { userListData, isFetching, totalData, isLoading } = useSelector(
    (state) => state.event
  );

  useEffect(() => {
    listEventData();
    dispatch(apiLoadingStart());
    dispatch(clearUserList());
  }, []);

  const handlePagination = (flag) => {
    dispatch(apiFatchingStart());
    const page = flag == "next" ? pageNo + 1 : pageNo - 1;
    setPageNo(page);
  };

  useEffect(() => {
    listEventData();
  }, [pageNo]);

  const listEventData = () => {
    let requestParams = {
      search: searchInput,
      userType: 3,
    };
    dispatch(allUserList(pageNo, limit, requestParams));
  };

  const handleSearch = (e) => {
    let search = e.target.value;
    setSearchInput(search);
    let requestParams = {
      search: search,
      userType: 3,
    };
    dispatch(allUserList(pageNo, limit, requestParams));
  };

  console.log(
    "Math.floor(totalData / limit) + 1 > pageNo+1 == ",
    Math.floor(totalData / limit),
    pageNo,totalData
  );

  return isLoading ? (
    <div
      className="position-absolute top-50 start-50 translate-middle"
      style={{ color: "var(--bs-lt-red)" }}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only"></span>
      </div>
    </div>
  ) : (
    <>
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="input-block">
            <label htmlFor="searchEvents" className="form-label">
              Search
            </label>
            <input
              type="text"
              className="form-control"
              value={searchInput}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="ticket-table mt-3">
        <div className="row heading">
          <div className="col-10 col-md-11">
            <div className="row">
              <div className="row">
                <div className="col-12 col-md-2">Name</div>
                <div className="col-12 col-md-2">User Name</div>
                <div className="col-12 col-md-3">Email</div>
                <div className="col-12 col-md-1">Status</div>
              </div>
            </div>
          </div>
          <div className="col-2 col-md-1"></div>
        </div>
        <div style={{ height: "auto", minHeight: "33rem" }}>
          {userListData && userListData.length != 0 ? (
            userListData?.map((item, key) => {
              return (
                <Userlistitem
                  itemData={item}
                  key={key}
                  isactiveTwo={isactiveTwo}
                  setActiveTwo={setActiveTwo}
                  openGroupMenuId={openGroupMenuId == item?.id}
                  handlemenuOpen={() => {
                    if (openGroupMenuId === item?.id) {
                      setGroupMenuId(-1);
                    } else {
                      setGroupMenuId(item?.id);
                    }
                  }}
                />
              );
            })
          ) : !isLoading ? (
            <p className="text-center pt-5"> No Data Found</p>
          ) : (
            ""
          )}
        </div>

        {userListData && userListData.length != 0 ? (
          <div className="mt-3" style={{ float: "right" }}>
            {isFetching ? (
              <div
                className="text-center"
                style={{ color: "var(--bs-lt-red)" }}
              >
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <a onClick={() => (pageNo > 1 ? handlePagination("pre") : "")}>
                  <i
                    className={
                      pageNo > 1
                        ? "fa-solid fa-circle-chevron-left fa-2xl paginationBtn"
                        : "fa-solid fa-circle-chevron-left fa-2xl paginationDisableBtn"
                    }
                  ></i>
                </a>
                <a
                  className="px-1"
                  onClick={() => {
                    if ((totalData % limit === 0 ?  Math.floor(totalData / limit): Math.floor(totalData / limit) +1) > pageNo) {
                      handlePagination("next");
                    }
                  }}
                >
                  <i
                    className={
                      ((totalData % limit === 0 ?  Math.floor(totalData / limit): Math.floor(totalData / limit) +1) > pageNo)
                        ? "fa-solid fa-circle-chevron-right fa-2xl paginationBtn"
                        : "fa-solid fa-circle-chevron-right fa-2xl paginationDisableBtn"
                    }
                  ></i>
                </a>
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UserDashboard;
