import { Outlet } from "react-router-dom";
import Drawer from "./drawer";
import { useEffect } from "react";

export default function DashboardLayout() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-3 col-xl-2 pt-lg-3 admin-left-column">
            <Drawer />
          </div>
          <div className="col-12 col-lg-9 col-xl-8 board">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
