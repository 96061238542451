import React from 'react';

const LoadingComponent = () => {
  return (
    <>
      <div
        className="position-absolute top-50 start-50 translate-middle"
        style={{ color: 'var(--bs-lt-red)' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </>
  );
};
export default React.memo(LoadingComponent);
