import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const WeButton = ({ ...props }) => {
  const { style, text, onClick, isLoading, icon, classes } = props;
  return (
    <Button className={classes ?? "btn primary-btn mt-2"} style={{ style }} onClick={onClick}>
      {icon}
      {isLoading && <Spinner as="span" animation="grow" size="sm" style={{ marginRight: 5 }} />}
      {text}
    </Button>
  );
};

export default WeButton;
