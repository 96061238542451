import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.css";

const ConfirmDialog = ({ ...props }) => {
  const { title, message, showDialog, onDialogClose, btnConfirm, saveBtnHide } =
    props;
  return (
    <Modal show={showDialog} centered>
      <Modal.Header>
        <Modal.Title>
          <div className="row">
            <div className="col-10">
              <h1 className="fs-5">{title} </h1>
            </div>
            <div className="col-2">
              <a
                className="close closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onDialogClose}
              >
                <i className="fa-solid fa-xmark"></i>
              </a>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        {!saveBtnHide ? (
          <>
            <Button className="btn secondary-btn" onClick={onDialogClose}>
              NO
            </Button>
            <Button
              className="btn primary-btn"
              variant="primary"
              onClick={btnConfirm}
            >
              YES
            </Button>
          </>
        ) : (
          <Button
            className="btn primary-btn"
            variant="primary"
            onClick={onDialogClose}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ConfirmDialog);
