import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import navConfig from "./config";
import { useDispatch } from "react-redux";
import { clearEventData } from "../../store/slice/event";

const NavItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setActive] = useState(0);

  useEffect(() => {
    activeMenu();
  }, []);

  const handleClearEvent = () => {
    dispatch(clearEventData());
  };

  const activeMenu = () => {
    const pathName = window.location.pathname;

    if (pathName.includes('dashboard')) {
      setActive(0);
    } else if (pathName.includes('events')) {
      setActive(1);
    } else if (pathName.includes('organizer')) {
      setActive(2);
    } else if (pathName.includes('customer')) {
      setActive(3);
    } else if (pathName.includes('settings')) {
      setActive(4);
    } else {
      setActive(-1);
    }
  };

  return (
    <>
      {navConfig.map((item, index) => (
        <li key={index}>
          <a
            className={isActive === index ? "active" : "none"}
            onClick={() => {
              handleClearEvent();
              setActive(index);
              navigate(item.path);
            }}
          >
            <span
              style={{
                width: "60px",
                marginRight: "5px",
              }}
            >
              {item.icon}
            </span>
            <span className="title">{item.title}</span>
          </a>
        </li>
      ))}
    </>
  );
};

export default React.memo(NavItem);
